import React from "react";
import classes from "./RoleDropdown.module.css";
import PropTypes from "prop-types";
const RoleDropdown = props => {
  let classNames = props.expanded
    ? [classes.RoleDropdown, classes.Expanded].join(" ")
    : classes.RoleDropdown;
  let mappedLists = [];

  if (props.lists) {
    mappedLists = props.lists.map(list => (
      <div
        key={list.id}
        className={classes.Role}
        onClick={() => props.selectList(list.id)}
      >
        {list.title}
      </div>
    ));
  }
  return <div className={classNames}>{mappedLists}</div>;
};

RoleDropdown.propTypes = {
  lists: PropTypes.array
};
export default RoleDropdown;
