import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  SET_AUTH
} from "../actions/actionTypes";
import { invalidateUser } from "./user";
import { invalidateDocuments } from "./documents";
import axios from "axios";
import { fetchUserIfNeeded } from "./user";

const loginUserSuccess = token => {
  localStorage.setItem("token", token);
  return {
    type: LOGIN_USER_SUCCESS,
    payload: {
      token
    }
  };
};
const loginUserFailure = err => {
  localStorage.removeItem("token");
  return {
    type: LOGIN_USER_FAILURE,
    payload: {
      status: err.status,
      message: err.message
    }
  };
};

const loginUserRequest = () => {
  return {
    type: LOGIN_USER_REQUEST
  };
};
const logoutUser = () => {
  return {
    type: LOGOUT_USER
  };
};

export const completeLogout = () => {
  localStorage.removeItem("token");
  return async dispatch => {
    await dispatch(invalidateUser());
    await dispatch(invalidateDocuments());
    dispatch(logoutUser());
  };
};

export const loginUser = (email, password) => {
  return async dispatch => {
    dispatch(loginUserRequest());
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/auth/local`,
        {
          identifier: email,
          password
        },
        { "Content-Type": "application/json" }
      );
      if (response && response.data.jwt) {
        dispatch(loginUserSuccess(response.data.jwt));
        dispatch(fetchUserIfNeeded());
      } else {
        dispatch(
          loginUserFailure({ status: 400, message: "Could not log user in." })
        );
      }
    } catch (e) {
      if (e.response) {
        let status = e.response.data.statusCode;
        let message = e.response.data.message[0].messages[0].message;
        console.log(message);
        dispatch(loginUserFailure({ status, message }));
      }
    }
  };
};

const setAuth = () => {
  let token = localStorage.getItem("token");
  return {
    type: SET_AUTH,
    payload: {
      token
    }
  };
};
const authNeeded = state => {
  let auth = state.auth;
  return auth.token == null;
};

export const setAuthIfNeeded = () => {
  return (dispatch, getState) => {
    if (authNeeded(getState())) {
      return dispatch(setAuth());
    }
  };
};
