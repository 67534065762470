import React from "react";
import classes from "./UserList.module.css";
import UserListTable from "../../components/UserList/UserListTable/UserListTable";

const UserList = props => {
  return (
    <div className={classes.UserList}>
      <UserListTable />
    </div>
  );
};
export default UserList;
