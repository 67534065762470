import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
/**
 * Import Reducers
 */
import auth from "../store/reducers/auth";
import user from "./reducers/user";
import documents from "./reducers/documents";

import { fetchUserIfNeeded } from "./actions/user";
import { setAuthIfNeeded } from "./actions/auth";
/* 
for redux devtools, have to wrap middleware with the enhancer
*/
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({ auth, user, documents });
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

store.dispatch(setAuthIfNeeded());
store.dispatch(fetchUserIfNeeded());

export default store;
