import React, { useEffect, useMemo } from "react";
import classes from "./UserListTable.module.css";
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination
} from "react-table";

import { useGetUserList } from "../../../hooks/useGetUserList/useGetUserList";
import Table from "./Table/Table";
import { isoToDate } from "../../../utlis/Date/isoToDate";

//function to only get the data we need.
const remapUser = user => {
  //create the dates.
  let dob = isoToDate(new Date(user.dob));
  let sLicense = user.documents.find(doc => doc.documentType === 2);
  // console.log(sLicense);
  // let speechLicExp
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    dob: dob,
    email: user.email,
    subroles: user.subroles
      ? user.subroles.map(subrole => subrole.title)
      : ["no role assigned"],
    username: user.username,
    blocked: user.blocked ? "Yes" : "No",
    office: user.office.title,
    startDate: user.startDate,
    sLicense: sLicense ? sLicense : "",
    thirtyDayReview: "",
    annualReview: ""
  };
};

const UserListTable = props => {
  let users = [];
  //retrieve the user list
  let [res, getUsers] = useGetUserList({
    path: "/users"
  });

  useEffect(() => {
    getUsers();
  }, []);
  //assign the columns for the table
  let columns = useMemo(
    () => [
      {
        Header: "Users",
        columns: [
          { Header: "Last Name", accessor: "lastName" },
          { Header: "First Name", accessor: "firstName" },
          {
            Header: "DOB",
            accessor: "dob"
          },
          { Header: "Position(s)", accessor: "subroles" },
          { Header: "Office", accessor: "office" },
          { Header: "Blocked", accessor: "blocked" },
          { Header: "Start Date", accessor: "startDate" },
          {
            Header: "S. License Renewal",
            Cell: ({ row }) => {
              const sLicense = row.original.sLicense;
              let linkText = "";
              let link = "";
              if (sLicense) {
                linkText = sLicense.expires
                  ? isoToDate(new Date(sLicense.expires))
                  : "View Document";
                link =
                  `${process.env.REACT_APP_BACKEND_ENDPOINT}` +
                  sLicense.file.url;
              }
              return (
                <p>
                  <a href={link}>{linkText}</a>
                </p>
              );
            }
          },
          { Header: "30-day Review", accessor: "thirtyDayReview" },
          { Header: "Annual Review", accessor: "annualReview" }
        ]
      }
    ],
    []
  );

  let testData = [
    {
      id: 1,
      username: "Strapi user",
      email: "user@strapi.io",
      firstName: "Strapi",
      lastName: "User",
      dob: "03/01/1993",
      subroles: ["Standard", "Admin"],
      blocked: "No"
    }
  ];

  if (res.users) {
    users = res.users.data.map(remapUser);
  }
  return (
    <div className={classes.UserListTable}>
      <Table columns={columns} data={users} />
    </div>
  );
};
export default UserListTable;
