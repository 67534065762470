import React from "react";
import classes from "./Login.module.css";
import { Form, Field } from "react-final-form";
import ThemeBtn, { btnTypes } from "../../UI/ThemeBtn/ThemeBtn";

/**
 * Handles form validation
 * @param {string} values
 * @returns errors: any validation errors to be handled in renderHandler
 */
const validationHandler = values => {
  const errors = {};
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!values.password) {
    errors.password = "Password is required";
  }
  return errors;
};

/**
 * Render handler for final form <Form> render param
 */
const renderHandler = ({
  handleSubmit,
  form,
  submitting,
  pristine,
  values
}) => (
  <form className={classes.LoginForm}>
    <label htmlFor="email">Email</label>
    <Field name="email">
      {({ input, meta }) => {
        return (
          <>
            <div>
              <input {...input} type="email" placeholder="Email" />
            </div>
            {/* display errors */}
            {meta.error && meta.touched && (
              <span className={classes.ValidationError}>{meta.error}</span>
            )}
          </>
        );
      }}
    </Field>

    <label htmlFor="password">Password</label>
    <Field
      name="password"
      component="input"
      type="password"
      placeholder="Password"
    >
      {({ input, meta }) => {
        return (
          <>
            <div>
              <input {...input} type="password" placeholder="Password" />
            </div>
            {/* display error */}
            {meta.error && meta.touched && (
              <span className={classes.ValidationError}>{meta.error}</span>
            )}
          </>
        );
      }}
    </Field>
    <div style={{ alignSelf: "flex-end" }}>
      <ThemeBtn click={handleSubmit} type={btnTypes.PRIMARY_SUBMISSION_LIGHT}>
        Login
      </ThemeBtn>
    </div>
  </form>
);

const login = props => {
  return (
    <Form
      onSubmit={props.submit}
      validate={validationHandler}
      render={renderHandler}
    ></Form>
  );
};

export default login;
