import { useState, useEffect } from "react";
import axiosApi from "../../utlis/axios/axiosApi";
export function useGetAutoLists() {
  const [lists, setLists] = useState([]);

  useEffect(() => {
    let mounted = true;
    let source = axiosApi.CancelToken.source();

    axiosApi
      .get("/todolists/auto", {
        cancelToken: source.token
      })
      .then(response => {
        if (mounted) {
          setLists(response.data);
        }
      })
      .catch(e => {
        // console.log("couldn't fetch roles.");
      });
    return () => {
      mounted = false;
      source.cancel();
    };
  }, [lists.length]);
  return [lists, setLists];
}
