import React, { Component } from "react";
import classes from "./AdminSettingsView.module.css";
import { Route, Switch } from "react-router-dom";
import AddUserSetting from "../../components/Settings/UserSettings/AddUserSetting/AddUserSetting";
import UserSettings from "../../components/Settings/UserSettings/UserSettings";
import TodoSettings from "../../components/Settings/TodoSettings/TodoSettings";
import InitListSetting from "../../components/Settings/TodoSettings/InitListSetting/InitListSetting";
class AdminSettingsView extends Component {
  render() {
    return (
      <>
        <div className={classes.SettingsColumn}>
          <h1 className={classes.HeaderText}>Settings: </h1>
          <UserSettings match={this.props.match} />
          <TodoSettings match={this.props.match} />
        </div>
        <div className={classes.DisplayColumn}>
          <Switch>
            <Route
              path={"/dashboard/admin-settings/user"}
              component={AddUserSetting}
            />
            <Route
              path={"/dashboard/admin-settings/manage-lists"}
              component={InitListSetting}
            />
          </Switch>
        </div>
      </>
    );
  }
}
export default AdminSettingsView;
