import React from "react";
import classes from "./Spinner.module.css";
import PropTypes from "prop-types";

export const spinnerTypes = {
  SPINNER_LT: "SPINNER_LT",
  SPINNER_DK: "SPINNER_DK"
};
const spinner = props => {
  let spinner;

  switch (props.type) {
    case spinnerTypes.SPINNER_LT: {
      spinner = <div className={classes.SpinnerLt}></div>;
      break;
    }
    case spinnerTypes.SPINNER_DK: {
      spinner = <div className={classes.SpinnerDk}></div>;
      break;
    }
    default:
      spinner = <div className={classes.SpinnerDk}></div>;
  }

  return <div>{spinner}</div>;
};
spinner.propTypes = {
  type: PropTypes.string.isRequired
};
export default spinner;
