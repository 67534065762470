import React from "react";
import classes from "./InitItem.module.css";
import Dropdown from "../../../UI/Icons/Dropdown";
const InitItem = props => {
  return (
    <div className={classes.InitItem}>
      {props.title}{" "}
      <div
        className={classes.DropdownContainer}
        onClick={() => props.expandItem(props.id)}
      >
        <Dropdown />
      </div>
    </div>
  );
};
export default InitItem;
