import React, { Component } from "react";
import "./App.css";
import LandingPage from "./containers/LandingPage/LandingPage";
import Dashboard from "./layouts/Dashboard/Dashboard";
import { Route, Switch } from "react-router-dom";
import { requireAuthentication } from "./hoc/ProtectedComponent/ProtectedComponent";
import NotFoundPage from "./containers/NotFoundPage/NotFoundPage";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route
            path="/dashboard"
            component={requireAuthentication(Dashboard)}
          />
          <Route path="/" exact component={LandingPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    );
  }
}

export default App;
