import React from 'react';
import classes from './Standard.module.css'
/*
Sets a full screen page with margins on the side. 
Uses css grid. use for single form page. Like login.
*/
const standard = props => {
    return (
        <div className = {classes.Standard}>
            {props.children}
        </div>
    );
}

export default standard;