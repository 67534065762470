import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  SET_AUTH
} from "../actions/actionTypes";
import jwtDecode from "jwt-decode";

const initialState = {
  token: null,
  isAuthenticated: false,
  isAuthenticating: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST: {
      const newVal = {
        ...state,
        isAuthenticating: true,
        statusText: null
      };
      return newVal;
    }
    case LOGIN_USER_SUCCESS: {
      const newVal = {
        token: action.payload.token,
        isAuthenticating: false,
        isAuthenticated: true,
        statusText: "You have been successfully logged in"
      };
      return newVal;
    }
    case LOGIN_USER_FAILURE: {
      const newVal = {
        token: null,
        isAuthenticating: false,
        isAuthenticated: false,
        statusText: `Auth Error: ${action.payload.status} ${action.payload.message}`
      };
      return newVal;
    }
    case LOGOUT_USER: {
      const newVal = {
        token: null,
        isAuthenticating: false,
        isAuthenticated: false,
        statusText: "Logout successful"
      };
      return newVal;
    }
    case SET_AUTH: {
      const newVal = {
        token: action.payload.token,
        isAuthenticated: action.payload.token == null ? false : true,
        isAuthenticating: false,
        statusText: "Token was Set"
      };
      return newVal;
    }
    default:
      return state;
  }
};

export default reducer;
