import React, { useState } from "react";
import classes from "./CreateTodo.module.css";
import CreateTodoForm from "../../../components/Forms/CreateTodoForm/CreateTodoForm";
import { useCreateTodo } from "../../../hooks/useCreateTodo/useCreateTodo";
import { Redirect } from "react-router-dom";
const CreateTodo = props => {
  const [res, createTodo] = useCreateTodo();
  const [submitted, setSubmitted] = useState(false);
  const submitTodo = data => {
    const remappedData = {
      ...data,
      expires: data.expires ? new Date(data.expires) : null,
      todolist: props.match.params.id,
      completed: false
    };
    createTodo(remappedData);

    setSubmitted(true);
  };
  const cancelCreate = () => {};
  return (
    <>
      {submitted && res.data ? (
        <Redirect to={"/dashboard/todo"} />
      ) : (
        <div className={classes.CreateTodo}>
          <h1 className={classes.CreateTodoTitle}>Add Item:</h1>
          <br />
          <CreateTodoForm
            isTemplate={false}
            submit={submitTodo}
            cancel={cancelCreate}
            submitText="Create"
            isEditing={false}
          />
        </div>
      )}
    </>
  );
};
export default CreateTodo;
