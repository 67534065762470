import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./LandingPage.module.css";
import Standard from "../../layouts/Standard/Standard";

import LoginForm from "../../components/Forms/Login/Login";
import Spinner, { spinnerTypes } from "../../components/UI/Spinner/Spinner";

import { loginUser } from "../../store/actions/auth";
class LandingPage extends Component {
  state = {
    signedIn: false
  };
  componentDidMount() {
    console.log(process.env.REACT_APP_BACKEND_ENDPOINT);
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuthenticated) {
      this.userSignInHandler();
    }
  }
  userSignInHandler = () => {
    this.setState({
      signedIn: true
    });
  };

  onSubmit = e => {
    this.props.login(e.email, e.password);
  };

  render() {
    /**This redirect is causing issues, only allows the app to refresh straight to
     * profile view.
     */
    const content = this.state.signedIn ? (
      <Redirect to="/dashboard/profile" />
    ) : (
      <div className={classes.LandingPage}>
        <Standard>
          <div className={classes.LoginFormContainer}>
            {this.props.user.isFetching ? (
              <Spinner type={spinnerTypes.SPINNER_LT} />
            ) : (
              <>
                <h1>Staff Login</h1>
                <LoginForm submit={this.onSubmit} />
              </>
            )}
          </div>
        </Standard>
      </div>
    );
    return <div>{content} </div>;
  }
}
const mapStateToProps = state => {
  const { auth, user } = state;
  return { auth, user };
};
const mapDispatchToProps = dispatch => {
  return {
    login: (email, password) => {
      dispatch(loginUser(email, password));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
