import React from "react";
import { ReactComponent as EditIc } from "./edit_icon.svg";
const Edit = props => {
  let styles = {
    maxHeight: "45px",
    maxWidth: "45px"
  };
  return <EditIc style={styles} />;
};
export default Edit;
