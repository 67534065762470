import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import classes from "./FileUploadAdapter.module.css";
function FileUploadAdapter(props) {
  const [files, setFiles] = useState([]);
  const onDrop = useCallback(
    acceptedFiles => {
      props.onChange(acceptedFiles);
      setFiles([...acceptedFiles]);
    },
    [props]
  );

  const {
    rejectedFiles,
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop,
    accept:
      "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel"
  });
  return (
    <div className={classes.FileUpload} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Hey, drop it here!</p>
      ) : (
        <p>Drag n drop some files here, or click to select files</p>
      )}
      {rejectedFiles.length ? (
        <div className={classes.UploadErr}>
          Sorry, that File can't be accepted
        </div>
      ) : null}
      <span className={classes.FileName}>{files.map(file => file.path)}</span>
    </div>
  );
}
export default FileUploadAdapter;
