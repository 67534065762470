import React from "react";
import classes from "./StdProfile.module.css";

/**
 * StdProfile: "Standard" Profile. General profile information that any user can see
 * about themselves, and that admin can see of them. (within user detail view.)
 */
const stdProfile = props => {
  let roles = props.user.subRoles;
  return (
    <div className={classes.StdProfile}>
      <h1 className={classes.Name}>
        {props.user.firstName} {props.user.lastName}
      </h1>
      <p className={classes.Roles}>
        {roles ? roles.map(role => role.title + " ") : null}
      </p>
      <p className={classes.Email}>{props.user.email}</p>
      <p className={classes.Dob}>
        <span role="img" aria-label="date of birth">
          &#127874;
        </span>
        {props.user.dob ? new Date(props.user.dob).toLocaleDateString() : "DOB"}
      </p>
    </div>
  );
};

export default stdProfile;
