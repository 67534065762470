import React from "react";
import classes from "./TodoSettings.module.css";
import { Link } from "react-router-dom";
const TodoSettings = ({ match, rest }) => {
  return (
    <div className={classes.TodoSettings}>
      <Link to={match + "/manage-lists"}>Manage "New Hire" Todo Lists</Link>
    </div>
  );
};
export default TodoSettings;
