import * as actionTypes from "../actions/actionTypes";
const initialState = {
  id: null,
  email: null,
  username: null,
  firstName: null,
  lastName: null,
  dob: null,
  startDate: null,
  roles: null,
  office: null,
  isFetching: null,
  didInvalidate: null,
  statusText: null
};
/**
 * Utilities
 */

/**
 *
 * @param {*} oldState
 * @param {*} newState
 * Note: should recieve {index: newObject}
 */
const updateUserObject = (oldState, newState) => {
  const newObj = Object.assign({}, oldState, { ...newState });
  return newObj;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_REQUEST: {
      const newVal = {
        ...state,
        isFetching: true,
        didInvalidate: false,
        statusText: "Fetching user"
      };
      return newVal;
    }
    case actionTypes.FETCH_USER_SUCCESS: {
      const newVal = {
        id: action.payload.id,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        dob: action.payload.dob,
        office: action.payload.office,
        startDate: action.payload.startDate,
        roles: { ...action.payload.role },
        subRoles: [...action.payload.subroles],
        isFetching: false,
        didInvalidate: false,
        statusText: "User was fetched Successfully"
      };

      return newVal;
    }
    case actionTypes.FETCH_USER_ERROR: {
      const newVal = {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        dob: null,
        office: null,
        startDate: null,
        roles: null,
        subRoles: null,
        isFetching: false,
        didInvalidate: false,
        statusText: `Error occured. Status: ${action.payload.status} Message: ${action.payload.messages}`
      };
      return newVal;
    }
    case actionTypes.INVALIDATE_USER: {
      const newVal = {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        dob: null,
        office: null,
        startDate: null,
        roles: null,
        subRoles: null,
        isFetching: null,
        didInvalidate: null,
        statusText: null
      };
      return newVal;
    }
    default:
      return state;
  }
};

export default reducer;
