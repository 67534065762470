import React from 'react';
import classes from './DashboardContent.module.css';
const dashboardContent = props =>{
    return (
        <div className={classes.DashboardContent}>
            {props.children}
        </div>
    )
}

export default dashboardContent;