import { useState, useCallback } from "react";
import axiosApi from "../../utlis/axios/axiosApi";
export function useGetUserList({ path }) {
  const [res, setRes] = useState({
    users: null,
    error: null,
    isLoading: false
  });

  const callApi = useCallback(() => {
    setRes(prevState => ({
      ...prevState,
      isLoading: true
    }));
    axiosApi
      .get("/users")
      .then(res => {
        setRes({ users: res, error: null, isLoading: false });
      })
      .catch(e => {
        console.log(e.response);
        setRes({ users: null, error: e.message, isLoading: false });
      });
  }, [path]);

  return [res, callApi];
}
