import { useState, useCallback } from "react";
import axiosApi from "../../utlis/axios/axiosApi";

/**
 * TODO: bring this functionality back.
 */
export function useCreateTodo() {
  const [res, setRes] = useState({
    data: null,
    error: null,
    isLoading: false
  });

  const callApi = useCallback(
    payload => {
      setRes(prevState => ({ ...prevState, isLoading: true }));
      axiosApi
        .post("/todos", payload)
        .then(response => {
          setRes({ data: response, error: null, isLoading: false });
        })
        .catch(err => {
          setRes({ data: null, error: err, isLoading: false });
        });
    },
    [res.data]
  );

  return [res, callApi];
}
