import React, { useState, useEffect } from "react";
import classes from "./InitListSetting.module.css";
import { useGetRoles } from "../../../../hooks/useGetRoles/useGetRoles";
import { useGetAutoLists } from "../../../../hooks/useGetAutoLists/useGetAutoLists";
import RoleDropdown from "../../../UI/RoleDropdown/RoleDropdown";
import Dropdown from "../../../UI/Icons/Dropdown";
import InitItem from "../InitItem/InitItem";
import ThemeBtn, { btnTypes } from "../../../UI/ThemeBtn/ThemeBtn";
import CreateTodoForm from "../../../Forms/CreateTodoForm/CreateTodoForm";
import { useCreateTodo } from "../../../../hooks/useCreateTodo/useCreateTodo";
/**Settings that handle the initial user list  */

//FIXME: Get this working with strapi!
const InitListSetting = props => {
  let listItems = null;
  const [expanded, setExpanded] = useState(false);
  const [expandAdd, setExpandAdd] = useState(false);
  // const [res, createTodoRequest] = useCreateTodo({
  //   path: "/create",
  //   headers: { "Content-Type": "application/json" }
  // });
  const [selectedList, setSelectedList] = useState({
    title: "Please Select a List"
  });
  const [lists, setLists] = useGetAutoLists();
  const expandItem = id => {
    // console.log("expanded: " + id);
  };
  // check if the list has todos.
  if (selectedList && selectedList.todos) {
    listItems = selectedList.todos.map(item => (
      <InitItem
        key={item.id}
        id={item.id}
        expandItem={expandItem}
        title={item.title}
        notes={item.notes}
      />
    ));
  }
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const selectList = listId => {
    let selected = lists.filter(list => list.id === listId);
    setExpanded(false);
    setSelectedList(selected[0]);
  };
  const expandAddItem = () => {
    setExpandAdd(!expandAdd);
  };

  const submitItem = data => {
    // const requestData = {
    //   ...data,
    //   listId: list.id,
    //   isTemplate: true
    // };
    // setList([]);
  };
  return (
    <div classame={classes.InitListSetting}>
      <div className={classes.TopSettings}>
        <div className={classes.SelectedRole} onClick={toggleExpanded}>
          {selectedList.title} <Dropdown />
        </div>
        <div className={classes.DropdownContainer}>
          <RoleDropdown
            expanded={expanded}
            lists={lists}
            selectList={selectList}
          />
        </div>
      </div>
      <br />
      {selectedList && listItems ? (
        <>
          <div className={classes.AddItemContainer}>
            <ThemeBtn
              click={expandAddItem}
              type={btnTypes.PRIMARY_SUBMISSION_LIGHT}
            >
              Add Item
            </ThemeBtn>
          </div>
          <br />
          {expandAdd ? (
            <CreateTodoForm isTemplate={true} submit={submitItem} />
          ) : null}
          <br />
          <div className={classes.ItemsListContainer}>{listItems}</div>
        </>
      ) : null}
    </div>
  );
};
export default InitListSetting;
