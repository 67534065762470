import { useState, useEffect } from "react";
import axiosApi from "../../utlis/axios/axiosApi";
export const useGetDocTypes = () => {
  let [res, setRes] = useState({
    types: null,
    error: null,
    isLoading: false
  });
  useEffect(() => {
    let mounted = true;
    let source = axiosApi.CancelToken.source();
    // let source = axios.CancelToken.source();
    let callApi = async () => {
      try {
        setRes({
          types: null,
          error: null,
          isLoading: true
        });

        let response = await axiosApi.get("/documenttypes", {
          cancelToken: source.token
        });
        if (response) {
          setRes({
            types: response.data,
            error: null,
            isLoading: false
          });
        }
      } catch (e) {
        setRes({
          types: null,
          error: e.message,
          isLoading: false
        });
      }
    };
    callApi();
    return () => {
      mounted = false;
      source.cancel();
    };
  }, []);
  return res;
};
