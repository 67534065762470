import React from "react";
import classes from "./CreateTodoForm.module.css";
import { Form, Field } from "react-final-form";
import ThemeBtn, { btnTypes } from "../../UI/ThemeBtn/ThemeBtn";
import PropTypes from "prop-types";

const CreateTodoForm = props => {
  const validator = values => {
    const errors = {};

    if (!values.title) {
      errors.title = "Title is required";
    }

    return errors;
  };
  const renderHandler = ({
    handleSubmit,
    form,
    submitting,
    pristine,
    values
  }) => (
    <form className={classes.CreateTodoForm}>
      <div className={classes.ExpiryDate}>
        {props.isTemplate ? (
          <>
            <label htmlFor="expiry">Expires in: </label>
            <Field
              className={classes.ExpiryNumber}
              component="input"
              name="expiryAmount"
              type="number"
              min="0"
            />
            <Field name="expiryMeasure" component="select">
              <option />
              <option value="day"> day(s) </option>
              <option value="month"> month(s) </option>
              <option value="year"> year(s) </option>
            </Field>
            <div>
              <small>Value of zero will not set a reminder.</small>
            </div>
          </>
        ) : (
          <>
            <label htmlFor="expires">Expires on: </label>
            <Field name="expires">
              {({ input, meta }) => {
                return (
                  <>
                    <div>
                      <input {...input} type="date" autoComplete="off" />
                    </div>
                    <small>Note: Expiry will set a reminder</small>
                  </>
                );
              }}
            </Field>
          </>
        )}
      </div>
      <br />
      <div className={classes.ExpiryDate}>
        <label htmlFor="title">Title: </label>
        <Field name="title">
          {({ input, meta }) => {
            return (
              <>
                <div>
                  <input {...input} type="text" autoComplete="off" />
                  {meta.error && meta.touched && (
                    <span className={classes.ValidationError}>
                      {meta.error}
                    </span>
                  )}
                </div>
              </>
            );
          }}
        </Field>
      </div>
      <div className={classes.Notes}>
        <label htmlFor="notes">Notes: </label>
        <Field name="notes" placeholder="Notes">
          {({ input, meta }) => {
            return (
              <>
                <div>
                  <textarea {...input} cols={45} autoComplete="off" />
                </div>
              </>
            );
          }}
        </Field>
      </div>
      <div className={classes.SubmitWrapper}>
        <ThemeBtn
          type={btnTypes.PRIMARY_ERR_LIGHT}
          click={props.cancel}
          disabled={submitting}
        >
          Cancel
        </ThemeBtn>
        <ThemeBtn
          type={btnTypes.PRIMARY_SUBMISSION_LIGHT}
          click={handleSubmit}
          disabled={submitting || pristine}
        >
          {props.submitText}
        </ThemeBtn>
      </div>
    </form>
  );
  // console.log("init ", props.initValues);
  return (
    <Form
      initialValues={props.initValues}
      onSubmit={props.submit}
      render={renderHandler}
      validate={validator}
    />
  );
};

CreateTodoForm.propTypes = {
  //function used to submit to the backend.
  submit: PropTypes.func.isRequired,
  //function cancels the edit/create.
  cancel: PropTypes.func.isRequired,
  //helps determine if this todo will serve as a template for creating other todos
  isTemplate: PropTypes.bool.isRequired,
  //submit button text (variable)
  submitText: PropTypes.string.isRequired,

  isEditing: PropTypes.bool.isRequired,

  initValues: PropTypes.object
};
export default CreateTodoForm;
