import React from "react";
import classes from "./Document.module.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import EditIcon from "../../../UI/Icons/Edit";
import DownloadIcon from "../../../UI/Icons/Download";
const Document = props => {
  const downloadLink = () => {
    window.location.href =
      `${process.env.REACT_APP_BACKEND_ENDPOINT}` + props.docLocation;
  };
  return (
    <div className={classes.Document}>
      <div className={classes.DocumentContent}>
        <div>
          <h2 className={classes.Title}>{props.title}</h2>
          <p className={classes.Description}>{props.description}</p>
        </div>
        <div className={classes.BottomRow}>
          <div className={classes.CreatedAt}>
            <strong>
              Created: {new Date(props.createdAt).toLocaleString()}
            </strong>
          </div>
          <div className={classes.Buttons}>
            <div className={classes.EditButton}>
              <Link to={props.parentRoute + "/edit/" + props.id}>
                <EditIcon />
              </Link>
            </div>
            <div
              className={classes.DownloadButton}
              onClick={() => downloadLink()}
            >
              <DownloadIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Document.propTypes = {
  //parent path required so the inner documents can
  //be edited correctly
  parentRoute: PropTypes.string.isRequired
};

export default Document;
