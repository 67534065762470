import React from "react";
import classes from "./DocumentsList.module.css";
import Document from "./Document/Document";
const DocumentsList = props => {
  let documentsList = [];
  if (props.documents) {
    documentsList = props.documents.map((document, idx) => {
      return (
        <Document
          key={idx}
          id={document.id}
          title={document.title}
          download={props.download}
          docLocation={document.file.url}
          parentRoute={props.parentRoute}
          description={document.description}
          createdAt={document.created_at}
        />
      );
    });
  }
  return (
    <div className={classes.DocumentsList}>
      {documentsList.length ? (
        documentsList
      ) : (
        <p>You have no documents to display</p>
      )}
    </div>
  );
};
export default DocumentsList;
