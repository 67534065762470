import React, { Component } from "react";
import classes from "./Todo.module.css";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import LeftColumn from "./LeftColumn/LeftColumn";
import RightColumn from "./RightColumn/RightColumn";
import CreateTodo from "./CreateTodo/CreateTodo";
import axios from "axios";
import axiosApi from "../../utlis/axios/axiosApi";
class ToDo extends Component {
  signal = axios.CancelToken.source();
  state = {
    userId: null,
    selectedItemIndex: null,
    completedItemID: null,
    items: []
  };

  componentWillUnmount() {
    this.signal.cancel("cancelling request.");
  }

  addTodoHandler = () => {
    console.log("add item");
  };
  render() {
    return (
      <>
        <Route
          exact
          path={"/dashboard/todo"}
          render={() => {
            return (
              <>
                <LeftColumn
                  addTodo={this.addTodoHandler}
                  userId={this.props.userId}
                  items={this.state.items}
                />
                <div className={classes.Divider}></div>
                {/* <RightColumn /> */}
              </>
            );
          }}
        />
        <Route exact path="/dashboard/todo/create/:id" component={CreateTodo} />
      </>
    );
  }
}
const mapStateToProps = ({ user }) => {
  const userId = user.id;
  return {
    userId
  };
};
export default connect(mapStateToProps)(ToDo);
