import React, { Component } from "react";
import classes from "./Dashboard.module.css";
/**Components */
import DashboardContent from "./DashboardContent/DashboardContent";
import Header from "../../components/Header/Header";
import Profile from "../../layouts/Profile/Profile";
import Documents from "../../layouts/Documents/Documents";
import ToDo from "../../layouts/ToDo/ToDo";
/*State*/
import { connect } from "react-redux";
import { completeLogout } from "../../store/actions/auth";
/*Navigation */
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import AdminSettings from "../AdminSettings/AdminSettings";
import UserList from "../UserList/UserList";
class Dashboard extends Component {
  state = {
    loggedOut: false
  };

  componentDidMount() {
    if (this.props.user.email === null) {
      this.logoutStateHandler();
    }
  }
  componentDidUpdate(prevProps) {}
  logout = () => {
    this.props.logout();
  };
  logoutStateHandler = () => {
    this.setState({
      loggedOut: true
    });
  };
  render() {
    const content = (
      <>
        <div className={classes.Dashboard}>
          <Header
            roles={this.props.user.subRoles}
            logout={this.logout}
          ></Header>
          <DashboardContent>
            <Switch>
              {/* Documents going through development, need to launch on refresh */}
              <Route
                path={[this.props.match.url + "/users"]}
                component={UserList}
              />
              <Route
                path={[
                  this.props.match.url + "/admin-settings",
                  this.props.match.url + "/admin-settings/user",
                  this.props.match.url + "/admin-settings/todo"
                ]}
                component={AdminSettings}
              />
              <Route
                path={[
                  this.props.match.url + "/documents",
                  this.props.match.url + "/documents/edit/:id"
                ]}
                component={Documents}
              />
              <Route
                path={[
                  this.props.match.url + "/todo",
                  this.props.match.url + "/todo/edit/:id"
                ]}
                component={ToDo}
              />
              <Route
                path={this.props.match.url + "/profile"}
                exact
                component={Profile}
              />
            </Switch>
          </DashboardContent>
        </div>
      </>
    );

    return <>{content}</>;
  }
}

const mapStateToProps = state => {
  const { user } = state;
  return {
    user
  };
};
const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(completeLogout());
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);
