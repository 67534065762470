import React, { useEffect, useState } from "react";
import classes from "./Header.module.css";
import NavLink from "../UI/NavLink/NavLink";
const Header = props => {
  let roles = props.roles ? props.roles : null;
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (roles) {
      setIsAdmin(roles.some(role => role.title === "Admin"));
    }
  }, [roles]);
  return (
    <div className={classes.Header}>
      <div className={classes.NavGroup}>
        <NavLink route="/dashboard/profile">Profile</NavLink>
        <NavLink route="/dashboard/todo">To Do</NavLink>
        <NavLink route="/dashboard/documents">Documents</NavLink>
        {isAdmin ? (
          <>
            <NavLink route="/dashboard/users">Users List</NavLink>
            <NavLink route="/dashboard/admin-settings">Admin Settings</NavLink>
          </>
        ) : null}
        <NavLink click={props.logout}>Logout</NavLink>
      </div>
      <div className={classes.LowerNavGroup}>2019, SpeechIC</div>
    </div>
  );
};

export default Header;
