import React from "react";
import classes from "./UserSettings.module.css";
import { Link } from "react-router-dom";
const UserSettings = ({ match, rest }) => {
  return (
    <div className={classes.UserSettings}>
      <Link to={match + "/user"}>Add User</Link>
    </div>
  );
};
export default UserSettings;
