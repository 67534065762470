import * as actionTypes from "../actions/actionTypes";
const initialState = {
  items: [],
  isFetching: false,
  didInvalidate: false
};
const updateDocumentsObject = (oldState, newState) => {
  const newObj = Object.assign({}, oldState, newState);
  return newObj;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_DOCUMENTS: {
      return updateDocumentsObject(state, {
        isFetching: true,
        didInvalidate: false
      });
    }
    case actionTypes.RECEIVE_DOCUMENTS: {
      return updateDocumentsObject(state, {
        items: [...action.payload.documents],
        isFetching: false,
        didInvalidate: false
      });
    }

    case actionTypes.INVALIDATE_DOCUMENTS: {
      return updateDocumentsObject(state, {
        items: [],
        isFetching: false,
        didInvalidate: true
      });
    }

    case actionTypes.RESET_DOCUMENTS: {
      return updateDocumentsObject(state, { ...initialState });
    }
    default:
      return state;
  }
};

export default reducer;
