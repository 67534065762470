import React from "react";
import classes from "./ToDo.module.css";
import ToDoContainer from "../../containers/Todo/Todo";

const ToDo = props => {
  return (
    <div className={classes.ToDo}>
      <ToDoContainer />
    </div>
  );
};
export default ToDo;
