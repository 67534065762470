import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

/**
 * More like a higher order function.
 */

export const requireAuthentication = Comp => {
  class ProtectedComponent extends Component {
    redirectToLogin = null;
    state = {
      isAuthenticated: false
    };
    componentDidMount() {
      if (this.props.isAuthenticated == true) {
        this.setState({ isAuthenticated: true });
      }
    }
    componentDidUpdate(prevProps) {
      if (prevProps.isAuthenticated != this.props.isAuthenticated) {
        this.setState({ isAuthenticated: this.props.isAuthenticated });
      }
    }
    render() {
      return (
        <>
          {this.props.isAuthenticated === true ? (
            <Comp {...this.props} />
          ) : (
            <>
              <Redirect to="/" />
            </>
          )}
        </>
      );
    }
  }
  const mapStateToProps = ({ auth, user }) => {
    return {
      token: auth.token,
      isAuthenticated: auth.isAuthenticated,
      isAuthenticating: auth.isAuthenticating
    };
  };
  return connect(mapStateToProps)(ProtectedComponent);
};
