import React from "react";
import classes from "./Reminders.module.css";
const reminders = props => {
  return (
    <div className={classes.Reminders}>
      <h1>Reminders will appear here</h1>
    </div>
  );
};
export default reminders;
