import React from "react";
import classes from "./FileUpload.module.css";
import PropTypes from "prop-types";
import FileUploadAdapter from "./FileUploadAdapter/FileUploadAdapter";
import { Form, Field } from "react-final-form";
import ThemeBtn, { btnTypes } from "../../UI/ThemeBtn/ThemeBtn";
import { useGetDocTypes } from "../../../hooks/useGetDocTypes/useGetDocTypes";

const FileUpload = props => {
  var initialValues = {
    title: "",
    description: "",
    document: "",
    documentType: "other"
  };
  let types = [];
  let typesResponse = useGetDocTypes();

  if (props.isEditing) {
    initialValues.title = props.title;
    initialValues.description = props.description;
    initialValues.documentType = props.documentType;
  }
  if (typesResponse.types) {
    types = typesResponse.types;
  }
  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={initialValues}
      validate={values => {
        const errors = {};
        if (!values.document && !props.isEditing) {
          errors.document = "* a Document is required";
        }
        if (!values.title) {
          errors.title = "Title is required";
        }
        if (!values.description) {
          errors.description =
            "Description is required. Keep it short and sweet.";
        }
        return errors;
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form className={classes.FileUpload}>
          <div>
            <label htmlFor="title">Title</label>
            <Field name="title">
              {({ input, meta }) => (
                <div>
                  <input {...input} type="text" placeholder="Title" />
                  {meta.error && meta.touched && (
                    <span className={classes.ValidationError}>
                      {meta.error}
                    </span>
                  )}
                </div>
              )}
            </Field>
            <label htmlFor="description">Description</label>
            <Field name="description">
              {({ input, meta }) => (
                <div>
                  <input {...input} type="text" placeholder="Description" />
                  {meta.error && meta.touched && (
                    <span className={classes.ValidationError}>
                      {meta.error}
                    </span>
                  )}
                </div>
              )}
            </Field>
            <label htmlFor="documentType">Document Type</label>
            <Field name="documentType" component="select">
              <option key={-1} value={"other"}>
                Other
              </option>
              {types.map(type => (
                <option key={type.id} value={type.id}>
                  {type.title}
                </option>
              ))}
            </Field>
            <Field
              type="file"
              name="document"
              render={props => {
                return (
                  <>
                    <FileUploadAdapter
                      name={props.input.name}
                      onChange={props.input.onChange}
                    />
                    {props.meta.error && (
                      <span className={classes.ValidationError}>
                        {props.meta.error}
                      </span>
                    )}
                  </>
                );
              }}
            />
          </div>
          <div className={classes.SubmitWrapper}>
            <ThemeBtn
              type={btnTypes.PRIMARY_SUBMISSION_LIGHT}
              click={handleSubmit}
              disabled={submitting}
            >
              Upload +
            </ThemeBtn>
          </div>
        </form>
      )}
    />
  );
};
FileUpload.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired
};

export default FileUpload;
