import React, { useState, useEffect } from "react";
import classes from "./ToDoList.module.css";
import ToDoItem from "./ToDoItem/ToDoItem";
import { Link } from "react-router-dom";
import ThemeBtn, { btnTypes } from "../../UI/ThemeBtn/ThemeBtn";
import { useGetTodoLists } from "../../../hooks/useGetTodos/useGetTodoLists";
import axiosApi from "../../../utlis/axios/axiosApi";
//TODO: Refactor. removing selected item handling as all selected items will just be expanded
const ToDoList = props => {
  const [todoList, getTodoList] = useGetTodoLists(props.userId);
  const [deletedItem, setDeletedItem] = useState(null);
  const [completedItem, setCompletedItem] = useState(null);
  useEffect(() => {
    getTodoList();
  }, [props.userId, deletedItem, completedItem]);

  let list = [];
  let todos = [];
  let completed = [];
  let listId = -1;
  const [selectedItem, setSelectedItem] = useState(-1);
  const [expandedItem, setExpandedItem] = useState(-1);

  const expandedToggle = id => {
    //set the view for the current item
    setSelectedItem(id);
    //if we're selecting a different item, expand it, otherwise, collapse it.
    if (id !== expandedItem) setExpandedItem(id);
    else setExpandedItem(-1);
  };

  const deleteHandler = id => {
    //confirmed
    if (window.confirm("Are you sure you wish to delete this item?")) {
      axiosApi
        .delete("/todos/" + id)
        .then(response => {
          setDeletedItem(response.data);
        })
        .catch(e => {});
    }
  };
  const completedHandler = (id, newState) => {
    let confirmText =
      newState === true
        ? "Mark todo as completed?"
        : "Mark todo as incomplete?";
    //confirmed
    if (window.confirm(confirmText)) {
      axiosApi
        .put("/todos/" + id, { completed: newState })
        .then(response => {
          setCompletedItem(response.data);
        })
        .catch(e => {});
    }
  };

  if (todoList && todoList.list) {
    list = todoList.list.todos;
    listId = todoList.list.id;
  }
  if (list.length) {
    // Seperate the list into two lists, keeping the incomplete items on top
    const incompleteItems = list.filter(item => !item.completed);
    const completedItems = list.filter(item => item.completed);

    todos = incompleteItems.map((todo, idx) => {
      return (
        <ToDoItem
          key={todo.id}
          id={todo.id}
          title={todo.title}
          expires={todo.expires}
          notes={todo.notes}
          createdAt={todo.created_at}
          completed={todo.completed}
          deleteHandler={deleteHandler}
          completedHandler={completedHandler}
          selected={selectedItem === todo.id}
          expanded={expandedItem === todo.id}
          expandedToggle={expandedToggle}
        />
      );
    });
    completed = completedItems.map(todo => {
      return (
        <ToDoItem
          key={todo.id}
          id={todo.id}
          title={todo.title}
          expires={todo.expires}
          notes={todo.notes}
          createdAt={todo.created_at}
          completed={todo.completed}
          deleteHandler={deleteHandler}
          completedHandler={completedHandler}
          selected={selectedItem === todo.id}
          expanded={expandedItem === todo.id}
          expandedToggle={expandedToggle}
        />
      );
    });
  }
  return (
    <>
      <div className={classes.SectionHeader}>
        <h1>Todo:</h1>{" "}
        {listId !== -1 ? (
          <Link to={"/dashboard/todo/create/" + listId}>
            <ThemeBtn type={btnTypes.PRIMARY_SUBMISSION_LIGHT} click={() => {}}>
              Add Todo +{" "}
            </ThemeBtn>
          </Link>
        ) : null}
      </div>
      <div className={classes.ToDoList}>{todos}</div>
      <div className={classes.SectionHeader}>Completed: </div>
      <div className={classes.ToDoList}>{completed}</div>
    </>
  );
};
export default ToDoList;
