import React, { useState } from "react";
import classes from "./ToDoItemDetail.module.css";
import ThemeBtn, { btnTypes } from "../../../../UI/ThemeBtn/ThemeBtn";
import CreateTodoForm from "../../../../Forms/CreateTodoForm/CreateTodoForm";
import { isoToInput } from "../../../../../utlis/Date/isoToInput";
import PropTypes from "prop-types";
import { useEditTodo } from "../../../../../hooks/useEditTodo/useEditTodo";
const ToDoItemDetail = props => {
  const [editing, setEditing] = useState(false);
  const [res, editTodo] = useEditTodo();
  const containerStyles = props.expanded
    ? [classes.ToDoItemDetail, classes.Expanded].join(" ")
    : classes.ToDoItemDetail;

  const todo = res.todo
    ? res.todo
    : {
        id: props.id,
        title: props.title,
        notes: props.notes,
        expires: isoToInput(props.expires)
      };
  const Submit = values => {
    let data = {};
    const title = values.title != todo.title ? values.title : null;
    const notes = values.notes != todo.notes ? values.notes : null;
    const expires = values.expires != todo.expires ? values.expires : null;

    if (title != null) {
      data["title"] = title;
    }
    if (notes != null) {
      data["notes"] = notes;
    }
    if (expires != null) {
      data["expires"] = expires;
    }
    editTodo(todo.id, data);

    setEditing(false);
  };
  const cancel = () => {
    setEditing(false);
  };
  if (res.todo) {
    props.updateTitle(res.todo.title);
    todo.expires = isoToInput(res.todo.expires);
  }
  return (
    <div className={containerStyles}>
      {!editing ? (
        <>
          <div className={classes.ExpiryBox}>
            <label htmlFor="expiry-date">
              <strong>Expires: </strong>
            </label>
            <div name="expiry-date" className="ExpiryDate">
              <p>
                {" "}
                {props.expires
                  ? new Date(todo.expires).toLocaleString()
                  : "No expiration"}
              </p>
            </div>
          </div>
          {/* <div className={classes.CreatedAtBox}>
            <label htmlFor="created-date">
              <strong>Created: </strong>{" "}
            </label>
            <div name="created-date" className="CreatedDate">
              <p> {new Date(todo.createdAt).toLocaleString()}</p>
            </div>
          </div> */}
          <div className={classes.NotesBox}>
            <label htmlFor="notes">
              <strong>Notes: </strong>
            </label>
            <p name="notes" className={classes.NotesText}>
              {todo.notes}
            </p>
          </div>
          <ThemeBtn
            click={() => setEditing(!editing)}
            type={btnTypes.PRIMARY_SUBMISSION_LIGHT}
          >
            Edit
          </ThemeBtn>
        </>
      ) : (
        <CreateTodoForm
          cancel={cancel}
          submitText="Submit"
          submit={Submit}
          isTemplate={false}
          isEditing={false}
          initValues={todo}
        />
      )}
    </div>
  );
};

ToDoItemDetail.propTypes = {
  expanded: PropTypes.bool.isRequired
};
export default ToDoItemDetail;
