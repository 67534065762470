import React from "react";
import { ReactComponent as DropdownIC } from "./dropdown.svg";
const Dropdown = props => {
  let style = {
    maxheight: "35px",
    maxWidth: "20px"
  };
  return <DropdownIC style={style} />;
};
export default Dropdown;
