import React, { Component } from "react";
import classes from "./ProfileView.module.css";
import { connect } from "react-redux";

import StdProfile from "../../components/Profile/StdProfile/StdProfile";

/**
 * ProfileView: container for all profile information.
 * Includes standard/general information for all users.
 * Also conditionally adds specific profile information for users based on their roles
 */
class ProfileView extends Component {
  render() {
    return (
      <div className={classes.ProfileView}>
        <StdProfile user={this.props.user} />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(
  mapStateToProps,
  null
)(ProfileView);
