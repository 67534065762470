import React from "react";
import classes from "./EditDocument.module.css";
import { useSelector } from "react-redux";
import FileUpload from "../../components/Documents/FileUpload/FileUpload";
const EditDocument = props => {
  const { match } = props;
  const document = useSelector(state => {
    const id = +match.params.id;

    return state.documents.items.find(document => document.id === id);
  });

  const onSubmitHandler = values => {
    console.log(values);
  };
  return (
    <div className={classes.EditDocument}>
      {document ? (
        <FileUpload
          isEditing={true}
          title={document.title ? document.title : ""}
          description={document.description ? document.description : ""}
          onSubmit={values => onSubmitHandler(values)}
        />
      ) : null}
    </div>
  );
};
export default EditDocument;
