import React, { useState } from "react";
import classes from "./AddUserSetting.module.css";
import AddUserForm from "../../../Forms/AddUserForm/AddUserForm";
import axiosUser from "../../../../utlis/axiosUser";
import { useGetRoles } from "../../../../hooks/useGetRoles/useGetRoles";
import Spinner, { spinnerTypes } from "../../../UI/Spinner/Spinner";

//FIXME: needs to work with Strapi
const AddUserSetting = props => {
  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  let roles = useGetRoles();

  if (roles === null) {
    roles = [];
  }

  const addUser = data => {
    let remappedRoles = data.roles.map(role => {
      let roleType = roles.find(el => el.title === role);
      return { id: roleType.id, title: role };
    });
    data.roles = remappedRoles;

    setIsAdding(true);

    axiosUser.post("/create", data).then(result => {
      if (result.data.status >= 400) {
        setSuccess(false);
        setError(true);
        setIsAdding(false);
        setMessage(result.data.message);
      } else {
        setSuccess(true);
        setError(false);
        setIsAdding(false);
        setMessage("");
      }
    });
  };
  return (
    <div className={classes.AddUserSetting}>
      {isAdding ? (
        <Spinner type={spinnerTypes.SPINNER_LT} />
      ) : (
        <AddUserForm submit={addUser} roles={roles} />
      )}
      {success ? "User added successfully." : null}
      {error ? "There was an error submitting the user info: " + message : null}
    </div>
  );
};
export default AddUserSetting;
