import * as actionTypes from "./actionTypes";
import axios from "axios";

/**
 * ASYNC USER ACTIONS
 */
const fetchUserRequest = () => {
  return {
    type: actionTypes.FETCH_USER_REQUEST,
    payload: {
      message: "User is logging in..."
    }
  };
};
const fetchUserSuccess = user => {
  return {
    type: actionTypes.FETCH_USER_SUCCESS,
    payload: {
      ...user
    }
  };
};

const fetchUserError = err => {
  return {
    type: actionTypes.FETCH_USER_ERROR,
    payload: {
      status: err.status,
      message: err.message
    }
  };
};

export const resetDocuments = () => {
  return { type: actionTypes.RESET_DOCUMENTS };
};

const shouldFetchUser = state => {
  const user = state.user;
  if (!user.email) {
    return true;
  } else if (!user.isFetching) {
    return false;
  } else {
    return user.didInvalidate;
  }
};

export const fetchUserIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchUser(getState())) {
      return dispatch(fetchUser());
    }
  };
};

export const fetchUser = () => {
  return async dispatch => {
    let token = localStorage.getItem("token");
    dispatch(fetchUserRequest());
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/users/me`,
        {
          headers: { Authorization: `Bearer ${token}` },
          "Content-Type": "application/json"
        }
      );
      if (response && response.data) {
        dispatch(fetchUserSuccess(response.data));
      } else {
        dispatch(
          fetchUserError({
            status: 400,
            message: "user could not be retrieved."
          })
        );
      }
    } catch (e) {
      dispatch(fetchUserError(e));
    }
  };
};

export const invalidateUser = () => {
  return {
    type: actionTypes.INVALIDATE_USER
  };
};
