import React, { Component } from "react";
import classes from "./DocumentsView.module.css";
/*** Redux*/
import { connect } from "react-redux";
import {
  fetchDocumentsIfNeeded,
  invalidateDocuments
} from "../../store/actions/documents";
/**Routing */
import { Switch, Route, withRouter } from "react-router-dom";
import FileUpload from "../../components/Documents/FileUpload/FileUpload";
import ThemeBtn, { btnTypes } from "../../components/UI/ThemeBtn/ThemeBtn";
import DocumentsList from "../../components/Documents/DocumentsList/DocumentsList";
import axios from "axios";
import EditDocument from "../EditDocument/EditDocument";
/**Document Download handling */
class DocumentsView extends Component {
  state = {
    expandedUpload: false,
    uploading: false
  };
  componentDidMount() {
    const { fetchDocumentsIfNeeded } = this.props;
    fetchDocumentsIfNeeded();
  }
  componentDidUpdate(prevProps) {
    if (this.props.documents.length !== prevProps.documents.length) {
      const { fetchDocumentsIfNeeded } = this.props;
      fetchDocumentsIfNeeded();
    }
  }
  toggleState = () => {
    this.setState({
      expandedUpload: !this.state.expandedUpload
    });
  };
  handleDocumentSubmit = data => {
    const { fetchDocumentsIfNeeded, invalidateDocuments } = this.props;
    this.setState({
      uploading: true
    });
    let dataBody = {
      title: data.title,
      description: data.description,
      owner: this.props.userId,
      documentType: data.documentType == "other" ? null : +data.documentType
    };
    var fd = new FormData();
    fd.append("data", JSON.stringify(dataBody));
    fd.append("files.file", data.document[0]);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_ENDPOINT}/documents`,
      data: fd,
      contentType: false,
      mimeType: "multipart/form-data",
      headers: {
        Authorization: `Bearer ${this.props.token}`
      }
    })
      .then(res => {
        invalidateDocuments();
        fetchDocumentsIfNeeded();
        this.setState({ uploading: false });
      })
      .catch(e => {});
  };
  render() {
    return (
      <>
        <div className={classes.DocumentsView}>
          <Switch>
            <Route
              path={this.props.match.url}
              exact
              render={props => (
                <>
                  <div className={classes.AddDocBtn}>
                    <ThemeBtn
                      type={btnTypes.PRIMARY_SUBMISSION_LIGHT}
                      click={() => this.toggleState()}
                    >
                      {this.state.expandedUpload
                        ? "Collapse -"
                        : "Add a Document +"}
                    </ThemeBtn>
                  </div>

                  {this.state.expandedUpload ? (
                    <div className={classes.FileUploadContainer}>
                      <FileUpload
                        isEditing={false}
                        onSubmit={this.handleDocumentSubmit}
                      />
                    </div>
                  ) : null}
                  <div className={classes.Divider}></div>
                  <DocumentsList
                    documents={this.props.documents.items}
                    parentRoute={this.props.match.url}
                  />
                </>
              )}
            />

            <Route
              path={this.props.match.url + "/edit/:id"}
              component={EditDocument}
            />
          </Switch>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  const { auth, user, documents } = state;
  return {
    userId: user.id,
    token: auth.token,
    documents
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchDocumentsIfNeeded: () => {
      dispatch(fetchDocumentsIfNeeded());
    },
    invalidateDocuments: () => {
      dispatch(invalidateDocuments());
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DocumentsView)
);
