import React from "react";
import classes from "./NavLink.module.css";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
/**
 *
 * @prop {func} click: for click action
 * @prop {string} route: optional route to navigate to. (if used with react router)
 */
const navLink = props => {
  var link;

  if (props.click) {
    link = (
      <div onClick={props.click} className={classes.NavLink}>
        {props.children}
      </div>
    );
  } else if (props.route) {
    link = (
      <div className={classes.NavLink}>
        <NavLink
          to={props.route}
          activeStyle={{
            fontWeight: "bold",
            color: "#ECF0E8",
            borderLeft: "#ECF0E8"
          }}
        >
          {props.children}
        </NavLink>
      </div>
    );
  } else {
    link = <div className={classes.NavLink}>{props.children}</div>;
  }

  return <>{link}</>;
};

navLink.propTypes = {
  click: PropTypes.func,
  route: PropTypes.string
};

export default navLink;
