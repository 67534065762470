import React from "react";
import classes from "./AddUserForm.module.css";
import { Form, Field } from "react-final-form";
import ThemeBtn, { btnTypes } from "../../UI/ThemeBtn/ThemeBtn";
const AddUserForm = props => {
  const validator = values => {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = "First name is required";
    }
    if (!values.temp_pass) {
      errors.temp_pass = "A Temporary password is required";
    }
    if (!values.conf_temp_pass) {
      errors.conf_temp_pass = "Please confirm the temporary password";
    }
    if (values.temp_pass !== values.conf_temp_pass) {
      errors.temp_pass_mismatch = "Passwords don't match.";
    }

    return errors;
  };
  const renderHandler = ({
    handleSubmit,
    form,
    submitting,
    pristine,
    values
  }) => (
    <form className={classes.AddUserForm}>
      <br />
      <div className={classes.NewEmployeeCheck}>
        <label htmlFor="newEmployee">New Employee?</label>{" "}
        <Field name="newEmployee" component="input" type="checkbox" />
      </div>
      <br />
      <div className={classes.Dob}>
        <label htmlFor="dob">Date of Birth: </label>
        <Field name="dob">
          {({ input, meta }) => {
            return (
              <>
                <div>
                  <input {...input} type="date" autoComplete="off" />
                </div>
              </>
            );
          }}
        </Field>
      </div>
      <div className={classes.Name}>
        <label htmlFor="first_name">First Name: </label>
        <Field name="first_name">
          {({ input, meta }) => {
            return (
              <>
                <div>
                  <input {...input} type="text" autoComplete="off" />
                  {meta.error && meta.touched && (
                    <span className={classes.ValidationError}>
                      {meta.error}
                    </span>
                  )}
                </div>
              </>
            );
          }}
        </Field>
      </div>
      <div className={classes.Name}>
        <label htmlFor="last_name">Last Name: </label>
        <Field name="last_name">
          {({ input, meta }) => {
            return (
              <>
                <div>
                  <input {...input} type="text" autoComplete="off" />
                  {meta.error && meta.touched && (
                    <span className={classes.ValidationError}>
                      {meta.error}
                    </span>
                  )}
                </div>
              </>
            );
          }}
        </Field>
      </div>
      <div>
        <label>Roles: </label>
        <div className={classes.Roles}>
          {props.roles.map(role => {
            return (
              <div
                key={role.id}
                style={role.title === "Standard" ? { display: "none" } : {}}
              >
                <label htmlFor="roles">
                  <Field
                    name="roles"
                    component="input"
                    type="checkbox"
                    value={role.title}
                  />{" "}
                  {role.title}
                </label>
              </div>
            );
          })}
          <small>These roles will determine what the user has access to.</small>
        </div>
      </div>
      <div className={classes.Email}>
        <label htmlFor="email">Email: </label>
        <Field name="email">
          {({ input, meta }) => {
            return (
              <>
                <div>
                  <input {...input} type="text" autoComplete="off" />
                  {meta.error && meta.touched && (
                    <span className={classes.ValidationError}>
                      {meta.error}
                    </span>
                  )}
                </div>
              </>
            );
          }}
        </Field>
      </div>
      <div className={classes.TempPassword}>
        <label htmlFor="temp_pass">Temporary Password: </label>
        <Field name="temp_pass">
          {({ input, meta }) => {
            return (
              <>
                <div>
                  <input {...input} type="text" autoComplete="off" />
                  {meta.error && meta.touched && (
                    <span className={classes.ValidationError}>
                      {meta.error}
                    </span>
                  )}
                </div>
              </>
            );
          }}
        </Field>
      </div>
      <div className={classes.ConfirmTempPass}>
        <label htmlFor="conf_temp_pass">Confirm Temporary Password: </label>
        <Field name="conf_temp_pass">
          {({ input, meta }) => {
            return (
              <>
                <div>
                  <input {...input} type="text" autoComplete="off" />
                  {meta.error && meta.touched && (
                    <span className={classes.ValidationError}>
                      {meta.error}
                    </span>
                  )}
                </div>
                <small>
                  User will be prompted to change their temporary password on
                  first login.
                </small>
              </>
            );
          }}
        </Field>
      </div>
      <br />
      <div className={classes.SubmitWrapper}>
        <ThemeBtn
          type={btnTypes.PRIMARY_SUBMISSION_LIGHT}
          click={handleSubmit}
          disabled={submitting}
        >
          Add +
        </ThemeBtn>
      </div>
    </form>
  );
  return (
    <Form
      onSubmit={props.submit}
      initialValues={{ "new-employee": true, roles: ["Standard"] }}
      render={renderHandler}
      validate={validator}
    />
  );
};
export default AddUserForm;
