import React, { useState } from "react";
import classes from "./ToDoItem.module.css";
import PropTypes from "prop-types";
import Checkbox from "../../../UI/Icons/Checkbox";
import CheckboxChecked from "../../../UI/Icons/CheckboxChecked";
import ToDoItemDetail from "./ToDoItemDetail/ToDoItemDetail";
import Delete from "../../../UI/Icons/Delete";
const ToDoItem = props => {
  //since the title could get modified by detail component. We'll hold it in a
  // state value
  const [title, setTitle] = useState(props.title);
  let todo = {
    title: props.title,
    expires: props.expires,
    description: props.description,
    completed: props.completed,
    createdAt: props.createdAt
  };

  /**
   * Helps us expand the current item and collapse all other items.
   * @param {number} id
   */
  const onSelectHandler = id => {
    props.expandedToggle(id);
  };
  const completedHandler = (id, newState) => {
    props.completedHandler(id, newState);
  };
  const deleteHandler = id => {
    props.deleteHandler(id);
  };
  /**
   * Update the displayed title, through state. Triggers re render.
   * @param {string} title
   */
  const updateTitle = title => {
    setTitle(title);
  };
  return (
    <>
      <div
        className={
          props.selected
            ? [classes.ToDoItem, classes.Selected].join(" ")
            : classes.ToDoItem
        }
      >
        <div className={classes.LeftContent}>
          <span
            className={classes.Checkbox}
            onClick={() => completedHandler(props.id, !props.completed)}
          >
            {props.completed ? (
              <CheckboxChecked width="35px" height="25px" />
            ) : (
              <Checkbox width="30px" height="30px" />
            )}
          </span>
          <div
            className={
              props.completed
                ? [classes.Task, classes.CompletedTask].join(" ")
                : classes.Task
            }
            onClick={() => onSelectHandler(props.id)}
          >
            {title}
          </div>
        </div>
        <div
          className={classes.RightContent}
          onClick={() => deleteHandler(props.id)}
        >
          <Delete width="30px" height="30px" />
        </div>
      </div>
      <ToDoItemDetail
        expanded={props.expanded}
        id={props.id}
        title={props.title}
        createdAt={props.createdAt}
        expires={props.expires}
        notes={props.notes}
        updateTitle={updateTitle}
      />
    </>
  );
};
ToDoItem.propTypes = {
  title: PropTypes.string.isRequired,
  expires: PropTypes.string,
  description: PropTypes.string,
  completed: PropTypes.bool.isRequired,
  createdAt: PropTypes.string
};
export default ToDoItem;
