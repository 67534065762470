import React from "react";
import classes from "./Profile.module.css";
import ProfileView from "../../containers/ProfileView/ProfileView";
import Reminders from "../../components/Profile/Reminders/Reminders";
const profile = props => {
  return (
    <div className={classes.Profile}>
      <div className={classes.LeftCol}>
        <ProfileView />
      </div>

      <div className={classes.RightCol}>
        <Reminders />
      </div>
    </div>
  );
};

export default profile;
