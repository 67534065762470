import React from "react";
import classes from "./Documents.module.css";
import DocumentsView from "../../containers/DocumentsView/DocumentsView";
const documents = props => {
  return (
    <div className={classes.Documents}>
      <DocumentsView {...props} />
    </div>
  );
};

export default documents;
