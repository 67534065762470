import moment from "moment";
/**
 * Converts iso date to a usable date string for inputs.
 * @param {string} isoDate: string representing mysql iso date.
 */
export const isoToInput = isoDate => {
  let dateTime = moment(isoDate);
  let inputReadableDate = dateTime.utc().format("YYYY-MM-DD");

  return inputReadableDate;
};
