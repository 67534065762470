import React from "react";
import classes from "./ThemeBtn.module.css";
import PropTypes from "prop-types";
/**
 * constants for type param
 */
export const btnTypes = {
  PRIMARY_SUBMISSION_LIGHT: "PRIMARY_SUBMISSION_LIGHT",
  PRIMARY_SUBMISSION_DARK: "PRIMARY_SUBMISSION_DARK",
  PRIMARY_ERR_LIGHT: "PRIMARY_ERR_LIGHT",
  PRIMARY_ERR_DARK: "PRIMARY_ERR_DARK"
};

/**
 *
 * @prop {func} click: function to use for onCLick()
 * @prop {string} type: type of custom button to use (see types above)
 */
const themeBtn = ({ click, type, children, ...props }) => {
  var styles = [];
  switch (type) {
    case btnTypes.PRIMARY_SUBMISSION_LIGHT: {
      styles = [classes.ThemeBtn, classes.PSLight];
      break;
    }
    case btnTypes.PRIMARY_SUBMISSION_DARK: {
      styles = [classes.ThemeBtn, classes.PSDark];
      break;
    }
    case btnTypes.PRIMARY_ERR_LIGHT: {
      styles = [classes.ThemeBtn, classes.PELight];
      break;
    }
    case btnTypes.PRIMARY_ERR_DARK: {
      styles = [classes.ThemeBtn, classes.PEDark];
      break;
    }
    default:
      styles = [classes.ThemeBtn];
  }
  if (props.disabled) {
    styles.push(classes.DisabledBtn);
  }
  return (
    <div className={styles.join(" ")} onClick={props.disabled ? null : click}>
      {children}
    </div>
  );
};
themeBtn.propTypes = {
  click: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};
export default themeBtn;
