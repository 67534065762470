import React from "react";
import classes from "./AdminSettings.module.css";
import AdminSettingsView from "../../containers/AdminSettingsView/AdminSettingsView";

const AdminSettings = ({ match, rest }) => {
  return (
    <div className={classes.AdminSettings}>
      <AdminSettingsView match={match.path} />
    </div>
  );
};
export default AdminSettings;
