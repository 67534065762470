import { useState, useCallback } from "react";
import axiosApi from "../../utlis/axios/axiosApi";
export function useGetTodoLists(userId) {
  const path = "/todolists/list/";
  const [res, setRes] = useState({
    list: null,
    error: null,
    isLoading: false
  });

  const callApi = useCallback(() => {
    setRes(prevState => ({
      ...prevState,
      isLoading: true
    }));
    if (userId) {
      axiosApi
        .get(path + `${userId}`)
        .then(response => {
          if (response.data && !response.data.message) {
            let primaryList = response.data.find(
              list => list.isPrimary === true
            );

            setRes({ list: primaryList, error: null, isLoading: false });
          }
        })
        .catch(e => {
          console.log(e.response);
          setRes({ list: null, error: e.message, isLoading: false });
        });
    }
  }, [userId]);

  return [res, callApi];
}
