import { useState, useEffect } from "react";
import axiosApi from "../../utlis/axios/axiosApi";
export function useGetRoles() {
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    let mounted = true;
    let source = axiosApi.CancelToken.source();
    //cant use the instance here, since we need access to the dumb "cancel token"
    axiosApi
      .get("/subroles", {
        cancelToken: source.token
      })
      .then(response => {
        if (mounted) {
          //remove standard role as everyone will have it.
          let roles = response.data;
          setRoles(roles);
        }
      })
      .catch(e => {
        console.log("couldn't fetch roles: ", e);
      });
    return () => {
      mounted = false;
      source.cancel();
    };
  }, []);
  return roles;
}
