import { useState, useCallback } from "react";
import axiosApi from "../../utlis/axios/axiosApi";

export function useEditTodo() {
  const [res, setRes] = useState({
    todo: null,
    isLoading: null,
    error: null
  });
  const editTodo = useCallback(
    (id, payload) => {
      setRes(prevState => ({ ...prevState, isLoading: true }));
      axiosApi
        .put("/todos/" + id, payload)
        .then(response => {
          if (response.data) {
            let item = response.data;
            item.createdAt = response.data.created_at;
            setRes({
              todo: response.data,
              isLoading: false,
              error: null
            });
          }
        })
        .catch(e => {
          setRes({
            todo: null,
            isLoading: false,
            error: e.message
          });
        });
    },
    [res.todo]
  );
  return [res, editTodo];
}
