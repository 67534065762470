import axios from "axios";
import {
  REQUEST_DOCUMENTS,
  RECEIVE_DOCUMENTS,
  INVALIDATE_DOCUMENTS
} from "./actionTypes";
export const invalidateDocuments = () => {
  return {
    type: INVALIDATE_DOCUMENTS
  };
};
export const requestDocuments = documents => {
  return {
    type: REQUEST_DOCUMENTS
  };
};

export const receiveDocuments = documents => {
  return {
    type: RECEIVE_DOCUMENTS,
    payload: {
      documents
    }
  };
};
const shouldFetchDocuments = state => {
  const documents = state.documents;
  if (!documents.items.length) {
    return true;
  } else if (documents.isFetching) {
    return false;
  } else {
    return documents.didInvalidate;
  }
};

export const fetchDocumentsIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchDocuments(getState())) {
      return dispatch(fetchDocuments());
    }
  };
};

export const fetchDocuments = () => {
  let token = localStorage.getItem("token");
  return dispatch => {
    dispatch(requestDocuments());
    axios
      .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/users/me`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        let documents = response.data.documents;
        dispatch(receiveDocuments(documents));
      })
      .catch(e => {
        console.log(e);
        dispatch(invalidateDocuments(e));
      });
  };
};
