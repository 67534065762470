/**
 * USER ACTION TYPES
 */
export const SET_AUTH = "SET_AUTH";
//Async action types for user.
export const FETCH_USER_REQUEST = "FETCH_USR_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USR_SUCCESS";
export const FETCH_USER_ERROR = "FETCH_USR_ERROR";
export const INVALIDATE_USER = "INVALIDATE_USER";

//Log in Log out action types.
export const LOG_IN_USER = "LOG_IN_USER";
export const LOG_IN_USER_STATUS = "LOG_IN_USER_STATUS";
export const LOG_OUT_USER = "LOG_OUT_USER";
export const LOG_OUT_USER_ERROR = "LOG_OUT_USER_ERROR";

//Alternate reducer functions ;
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";
/**
 * Document Action types
 */
export const REQUEST_DOCUMENTS = "REQUEST_DOCUMENTS";
export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const RECEIVE_DOCUMENTS = "RECIEVE_DOCUMENTS";
export const INVALIDATE_DOCUMENTS = "INVALIDATE_DOCUMENTS";
export const RESET_DOCUMENTS = "RESET_DOCUMENTS";
