import React from "react";
import { ReactComponent as Download } from "./download_icon.svg";
const DownloadIcon = props => {
  let style = {
    maxheight: "45px",
    maxWidth: "45px"
  };
  return <Download style={style} />;
};
export default DownloadIcon;
