import React, { useEffect } from "react";

import classes from "./LeftColumn.module.css";
import ToDoList from "../../../components/ToDo/ToDoList/ToDoList";
const LeftColumn = props => {
  return (
    <div className={classes.LeftColumn}>
      <ToDoList match={props.match} userId={props.userId} />
    </div>
  );
};

export default LeftColumn;
